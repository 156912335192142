import { RawVideoPlayerData, VideoPlayerSettings } from '@activebrands/core-web/libs/storyblok/storyblok';

/**
 * Accepts raw video data from Storyblok and interperates it either as a video bynder or video element.
 */

const transformVideoPlayerSettings = (rawVideoData: RawVideoPlayerData): VideoPlayerSettings => {
    switch (rawVideoData.component) {
        case 'videoBynder':
            return {
                autoPlay: rawVideoData.autoplay === '1',
                controls: rawVideoData.controls === '1',
                loop: rawVideoData.loop === '1',
                muted: rawVideoData.muted === '1',
                playsInline: rawVideoData.autoplay === '1',
                poster: rawVideoData.asset?.item?.derivatives?.thumbnail || '',
                src: rawVideoData.asset?.item?.previewUrls?.[0] || '',
            };

        case 'videoElement':
            return {
                autoPlay: rawVideoData.autoplay === '1',
                controls: rawVideoData.controls === '1',
                loop: rawVideoData.loop === '1',
                muted: rawVideoData.muted === '1',
                playsInline: rawVideoData.autoplay === '1',
                poster: rawVideoData.poster?.[0]?.filename || '',
                src: rawVideoData.video?.[0]?.filename || '',
            };
    }
};

export default transformVideoPlayerSettings;
