import { useEffect } from 'react';

declare global {
    interface Window {
        d365mktforms: Record<string, any>;
    }
}

interface NewsletterWidgetProps {
    _uid: string;
    formApiBaseUrl: string;
    formId: string;
    formUrl: string;
    id?: string;
}

/**
 * NewsletterWidget is a component that prints a div and creates a form from d365 inside.
 *
 * @param {LipScoreProductWidgetProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 *
 * @see {@link https://learn.microsoft.com/en-us/dynamics365/customer-insights/journeys/developer/realtime-marketing-form-client-side-extensibility}
 */

const NewsletterWidget = ({ _uid, formApiBaseUrl, formId, formUrl, id, ...rest }: NewsletterWidgetProps) => {
    if (!_uid || !formApiBaseUrl || !formId || !formUrl) {
        return null;
    }

    // The div id must be unique for each form
    const divId = `${formId}${_uid}`;

    useEffect(() => {
        const divElement = document.getElementById(divId);
        if (window.d365mktforms && divElement) {
            divElement.appendChild(window.d365mktforms.createForm(formId, formApiBaseUrl, formUrl));
        }
    }, []);

    return (
        <div id={id} {...rest}>
            <div id={divId} />
        </div>
    );
};

export default NewsletterWidget;
