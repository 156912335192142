import { graphql, useStaticQuery } from 'gatsby';

const useReclaimItUrlQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            dedicatedPages {
                                reclaimItPage
                            }
                        }
                    }
                }
            }
        `
    );
    return application.data.config.dedicatedPages || {};
};

export default useReclaimItUrlQuery;
