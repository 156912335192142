import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import NewsletterWidget from '@activebrands/core-web/components/NewsletterWidget';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Newsletter = ({
    $style = {},
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    heading = '',
    headingType = 'h2',
    isPopup = false,
    contentLayout = 'default',
    newsletter = {},
    paragraph = '',
    tag = '',
    textColor = '',
}) => {
    const [css] = useStyletron();

    const layoutVertical = contentLayout === 'vertical';
    const layoutHorizontal = contentLayout === 'horizontal';

    return (
        <div
            className={css({
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'grid',
                padding: isPopup ? '36px 0 0' : 0,
                gridTemplateColumns: 'repeat(12, 1fr)',
                ...$style,
            })}
        >
            <BackgroundDynamic
                background={background}
                backgroundOverlay={backgroundOverlay}
                loading={backgroundLoading}
            />

            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '24px',
                    padding: isPopup ? '0 16px' : ['16px', null, null, null, '18px'],
                    gridColumn: layoutVertical
                        ? '1 / span 12'
                        : layoutHorizontal
                        ? '1 / span 4'
                        : ['1 / span 12', null, null, null, '1 / span 4'],
                    justifyContent: layoutVertical
                        ? 'flex-start'
                        : layoutVertical
                        ? 'center'
                        : ['flex-start', null, null, null, 'center'],
                    ...getTextColorStyles(textColor),
                })}
            >
                <div>
                    {tag && (
                        <Tag
                            fontKeys={
                                isPopup
                                    ? ['Secondary/16_100_-02', null, null, null, 'Secondary/24_100_-03']
                                    : 'Secondary/24_100_-03'
                            }
                            $style={{
                                marginBottom: '12px',
                                width: 'fit-content',
                                border: '0',
                                padding: '0',
                            }}
                        >
                            {tag}
                        </Tag>
                    )}
                    {heading && (
                        <Heading
                            $style={{ marginBottom: isPopup ? ['16px', null, null, null, '24px'] : '24px' }}
                            as={headingType}
                            fontKeys={
                                isPopup
                                    ? ['Primary/24_-015', null, null, null, 'Primary/40_-05']
                                    : ['Primary/40_-05', null, null, null, 'Primary/64_-12']
                            }
                        >
                            {heading}
                        </Heading>
                    )}
                </div>
                {paragraph && (
                    <div
                        className={css({
                            width: ['100%', '90%', '75%'],
                            ...textStyles['Secondary/16_130_-02'],
                        })}
                    >
                        <RichText data={paragraph} />
                    </div>
                )}
            </div>
            <div
                className={css({
                    display: 'flex',
                    backgroundColor: 'var(--color-bg-header-overlay)',
                    gridColumn: layoutVertical
                        ? '1 / span 12'
                        : layoutHorizontal
                        ? '7 / span 6'
                        : ['1 / span 12', null, null, null, '7 / span 6'],
                    justifyContent: 'center',
                    alignItems: 'center',
                })}
            >
                <NewsletterWidget {...newsletter} />
            </div>
        </div>
    );
};

Newsletter.propTypes = {
    $style: PropTypes.object,
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    contentLayout: PropTypes.oneOf(['default', 'horizontal', 'vertical']),
    heading: PropTypes.string,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    isPopup: PropTypes.bool,
    newsletter: PropTypes.object,
    paragraph: PropTypes.object,
    tag: PropTypes.string,
    textColor: PropTypes.string,
};

export default Newsletter;
