import { graphql, useStaticQuery } from 'gatsby';

const useSearchQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            headerConfig {
                                searchNoResultText
                                searchInput
                                searchNoResultLabel
                            }
                            sortConfig {
                                enabledFacets {
                                    facet
                                }
                            }
                            dedicatedPages {
                                searchPage
                            }
                        }
                    }
                }
            }
        `
    );

    const { headerConfig, dedicatedPages, sortConfig } = application.data.config;
    return {
        headerConfigContent: headerConfig || {},
        searchPage: dedicatedPages?.searchPage,
        enabledFacets: sortConfig?.enabledFacets?.map((f: any) => f.facet),
    };
};

export default useSearchQuery;
