import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';
import ButtonsList from 'components/buttons/ButtonsList';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';

const Wrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
});

const ContentWrapper = styled('div', {
    position: 'relative',
    height: '100%',
});

const ContentBox = styled('div', {
    alignContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: 'inherit',
    zIndex: 1,
});

const MaxWidthWrapper = styled('div', {
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    width: '100%',
});

const Flexible = ({
    background,
    backgroundLoading = 'eager',
    backgroundOverlay,
    buttons = [],
    contentHorizontalAlignment = 'center',
    contentVerticalAlignment = 'center',
    heading,
    headingType = 'h2',
    headingStyle = 'md',
    id,
    layout = 'oneColumn',
    playButtonCallback,
    playButtonLabel,
    tag = '',
    text,
    textColor = 'black',
    wrapperLink,
}) => {
    const tagFontKeys = {
        oneColumn: {
            sm: ['Secondary/16_130_-02', null, null, 'Secondary/28_100_-0525', null, 'Secondary/32_100_-06'],
            md: ['Secondary/20_100_-0375', null, null, 'Secondary/40_100_-075'],
        },
        twoColumns: {
            sm: ['Secondary/16_100_-02', null, null, null, null, 'Secondary/20_100_-0375'],
            md: ['Secondary/20_100_-0375', null, null, 'Secondary/24_100_-03', null, 'Secondary/32_100_-06'],
        },
        threeColumns: {
            sm: ['Secondary/16_100_-02'],
            md: ['Secondary/20_100_-0375', null, null, null, null, 'Secondary/24_100_-03'],
        },
    };

    const headingFontKeys = {
        oneColumn: {
            sm: ['Secondary/32_100_-06', null, null, 'Secondary/56_100_-105', null, 'Secondary/64_100_-12'],
            md: ['Primary/40_-05', null, null, 'Primary/80_-15', null, 'Primary/96_-24'],
        },
        twoColumns: {
            sm: ['Secondary/32_100_-06', null, null, null, null, 'Secondary/40_100_-075'],
            md: ['Primary/40_-05', null, null, 'Primary/48_-09', null, 'Primary/64_-12'],
        },
        threeColumns: {
            sm: ['Secondary/32_100_-06'],
            md: ['Primary/40_-05', null, null, null, null, 'Primary/48_-09'],
        },
    };

    const [css] = useStyletron();
    const hasButtons = buttons.length > 0;

    const contentWrapperStyling = {
        oneColumn: { padding: ['12px', null, null, '16px', null, '24px'] },
        twoColumns: { padding: ['8px 8px 16px 8px', null, null, '12px 12px 24px 12px'] },
        threeColumns: { padding: ['8px 8px 16px 8px', null, null, '12px 12px 24px 12px'] },
    };

    const contentButtonStyling = {
        black: 'defaultsecondary',
        white: 'invertedsecondary',
    };

    const textColorStyle = getTextColorStyles(textColor);
    const positionStyles = getPositionStyles({ contentHorizontalAlignment, contentVerticalAlignment });
    const contentWrapperStyles = contentWrapperStyling[layout];

    return (
        <Wrapper id={id}>
            <Link className={css(textColorStyle)} to={wrapperLink}>
                <BackgroundDynamic
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading={backgroundLoading}
                />
                <ContentWrapper
                    $style={{ textAlign: contentHorizontalAlignment, ...contentWrapperStyles, ...textColorStyle }}
                >
                    <MaxWidthWrapper $style={positionStyles}>
                        <ContentBox>
                            {tag && (
                                <Tag fontKeys={tagFontKeys?.[layout]?.[headingStyle]} $style={{ marginBottom: '8px' }}>
                                    {tag}
                                </Tag>
                            )}
                            {heading && (
                                <Heading
                                    $style={{ marginBottom: '24px' }}
                                    as={headingType}
                                    fontKeys={headingFontKeys?.[layout]?.[headingStyle]}
                                >
                                    {heading}
                                </Heading>
                            )}
                            {text && (
                                <div className={css({ ...positionStyles })}>
                                    <div
                                        className={css({
                                            ...textStyles['Secondary/16_130_-02'],
                                            width: ['100%', null, '80%', null, null, '66%'],
                                            margin: contentHorizontalAlignment === 'center' ? '0 auto' : 'unset',
                                            display: 'inline-flex',
                                        })}
                                    >
                                        <RichText data={text} />
                                    </div>
                                </div>
                            )}
                            {hasButtons && (
                                <ButtonsList
                                    $style={{
                                        ...positionStyles,
                                        flexWrap: 'wrap',
                                        marginTop: ['24px', null, null, null, null, '32px'],
                                    }}
                                    buttons={buttons}
                                />
                            )}
                            {playButtonCallback && (
                                <div className={css({ width: 'auto', display: 'flex', justifyContent: 'center' })}>
                                    <ThemeButton
                                        $style={{
                                            display: 'flex',
                                            gap: '0 16px',
                                            alignItems: 'center',
                                            margin: '0',
                                        }}
                                        fontKeys="Misc/16_100_-02"
                                        theme={contentButtonStyling[textColor]}
                                        onClick={playButtonCallback}
                                    >
                                        {playButtonLabel}
                                    </ThemeButton>
                                </div>
                            )}
                        </ContentBox>
                    </MaxWidthWrapper>
                </ContentWrapper>
            </Link>
        </Wrapper>
    );
};

Flexible.propTypes = {
    background: PropTypes.arrayOf(PropTypes.object),
    backgroundLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            theme: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    contentHorizontalAlignment: PropTypes.oneOf(['left', 'right', 'center']),
    contentVerticalAlignment: PropTypes.oneOf(['top', 'bottom', 'center']),
    heading: PropTypes.string,
    headingStyle: PropTypes.oneOf(['sm', 'md']),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    layout: PropTypes.oneOf(['oneColumn', 'twoColumns', 'threeColumns']),
    playButtonCallback: PropTypes.func,
    playButtonLabel: PropTypes.string,
    tag: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textColor: PropTypes.string,
    wrapperLink: PropTypes.string,
};

export default Flexible;
